define('ember-intl/utils/macro', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = createTranslatedComputedProperty;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];return arr2;
    } else {
      return Array.from(arr);
    }
  }

  /*
   * <3 ember-i18n <3
   * https://github.com/jamesarosen/ember-i18n/blob/master/addon/utils/macro.js
   */

  var get = _ember['default'].get;
  var assert = _ember['default'].assert;
  var computed = _ember['default'].computed;

  var keys = Object.keys;

  function values(object) {
    return keys(object).map(function (key) {
      return object[key];
    });
  }

  function mapPropertiesByHash(object, hash) {
    var result = {};

    keys(hash).forEach(function (key) {
      result[key] = get(object, hash[key]);
    });

    return result;
  }
  function createTranslatedComputedProperty(key, options) {
    var hash = options || Object.create(null);
    var dependentKeys = ['intl.locale'].concat(values(hash));

    return computed.apply(undefined, _toConsumableArray(dependentKeys).concat([function () {
      var intl = get(this, 'intl');
      assert('Cannot translate "' + key + '".\n' + this + ' does not have an intl property set. Try: intl: Ember.inject.service()', intl);

      return intl.t(key, mapPropertiesByHash(this, hash));
    }])).readOnly();
  }
});